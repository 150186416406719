import { Response, Success } from '@sdk/sdk';

import { Injectable } from '@angular/core';
import { CookieService } from '../../infrastructure/cookie.service';
import { AuthRepository } from '../repositories/auth.repository';

@Injectable({
  providedIn: 'root'
})
export class LogOutUseCase {
  constructor(
    private _repository: AuthRepository,
    private _cookieService: CookieService
  ) {}

  async execute(): Promise<Response<boolean, SignOutError>> {
    await this._repository.logOut();
    const pastDate = new Date(0); // January 1, 1970
    this._cookieService.setCookieWithDate('SYTEX_DUPLICATED_DEVICE', '', pastDate);
    return new Success(true);
  }
}

export abstract class SignOutError {}
