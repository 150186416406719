import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookieService {
  getCookie(name: string): string | null {
    const nameEQ = name + '=';
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i].trim();
      if (cookie.startsWith(nameEQ)) {
        return cookie.substring(nameEQ.length, cookie.length);
      }
    }
    return null;
  }

  setCookie(name: string, value: string, days: number): void {
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toUTCString();
    }
    // Get the current domain and remove subdomains if any
    const hostParts = window.location.hostname.split('.');
    const domain = hostParts.length > 1 ? `.${hostParts.slice(-2).join('.')}` : window.location.hostname;

    document.cookie = name + '=' + (value || '') + expires + '; path=/; domain=' + domain;
  }

  setCookieWithDate(name: string, value: string, expirationDate: Date): void {
    const expires = '; expires=' + expirationDate.toUTCString();

    // Get the current domain and remove subdomains if any
    const hostParts = window.location.hostname.split('.');
    const domain = hostParts.length > 1 ? `.${hostParts.slice(-2).join('.')}` : window.location.hostname;

    document.cookie = name + '=' + (value || '') + expires + '; path=/; domain=' + domain;
  }
}
