import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { CookieService } from '../../infrastructure/cookie.service';

@Injectable({
  providedIn: 'root'
})
export class CreateUniqueIdUseCase {
  constructor(private cookieService: CookieService) {}

  async execute() {
    let device_id = this.cookieService.getCookie('SYTEX_DEVICE_ID');
    if (!device_id) {
      let uuid = uuidv4();
      device_id = `web|${uuid}`;
      this.cookieService.setCookie('SYTEX_DEVICE_ID', device_id, 365);
    }
  }
}
